import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import Container from '../components/Container';
import Card from '../components/Card';
import { headers } from '../headers';
import Header from '../components/Header';
import { StaticImage } from 'gatsby-plugin-image';

export default function WhoIAm() {
  const description =
    "Depuis toute petite, je suis attirée par le monde animalier et plus particulièrement par les chiens et leur comportement. À l'âge de 17 ans, j’ai commencé à aider des personnes de mon entourage dans l'éducation de leur chien.\n\nPar la suite, en parallèle de mon métier de conseillère vendeuse en animalerie, j’ai donné gratuitement des séances d’éducation canine pendant plus de 4 ans à des maîtres motivés par l'idée d'apprendre et évoluer avec leur animal.\n\nSur cette période, j’ai décidé de partager ma vie avec ma chienne Orphée. Dotée d'un grand caractère, elle a été formée par mes soins afin de m’aider au quotidien à montrer les bons codes canin aux autres chiens. Elle peut m’accompagner et intervenir durant les séances pour certains problèmes de comportement (agressivité envers les congénères, travail du rappel, chien harceleur, anxieux ou encore victime de troubles psychologiques).\n\nJ’ai obtenu ma formation d’éducateur canin au CFPPA du Valdoie en décembre 2021 pour enfin en faire mon métier a plein temps.";
  return (
    <Container>
      <Box mt={8} maxW="700px">
        <Card w="100%">
          <Box
            p={4}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            w="100%"
            gap={4}
          >
            <StaticImage
              src="../images/picture_anais_and_orphee.jpg"
              alt="Photo d'Anaïs et de sa chienne Orphée"
              width={300}
              height={300}
              style={{ borderRadius: '100%' }}
            />
            <Text fontWeight="bold" fontSize="large">
              Anaïs Seynaeve
            </Text>
            <Text whiteSpace="pre-line">{description}</Text>
          </Box>
        </Card>
      </Box>
    </Container>
  );
}

export function Head() {
  return <Header {...headers.whoiam} />;
}
